@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;

  box-sizing: border-box;
}

body {
  background-color: #F1F9F9;

  font-family: congenial, sans-serif;
  font-style: normal;

  /*overflow-x: hidden;*/
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}
h1 {
  font-size: 2em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.17em;
}
h4 {
  font-size: 1em;
}

.a-text-dec-none a {
  text-decoration: none;
}

.a-color-2C a {
  color: #2C2C2C;
}

p, ul {
  font-size: larger;
  font-family: congenial-light, sans-serif;
  font-weight: 500;
}

ul {
  list-style-type: square;
  padding-left: 20px;
}

li {
  padding-bottom: 5px;
  padding-top: 5px;
}

img {
  display: block;
}

.css-test {}


/******* box-sizing *******/
.box-sizing-content {box-sizing: content-box}
.box-sizing-bb {box-sizing: border-box}

/******* position *******/
.pos-fixed {position: fixed}
.pos-relative {position: relative}
.pos-absolute {position: absolute}
.pos-inherit {position: inherit}

/******* overflow *******/
.overflow-auto {overflow: auto}
.overflow-auto {overflow: overlay}
.overflow-vis {overflow: visible}
.overflow-hid {overflow: hidden}
.overflow-x-hid {overflow-x:hidden}
.overflow-x-clip {overflow-x:clip}
.overflow-y-hid {overflow-y:hidden}

/******* links *******/
.text-dec-none {text-decoration: none}
.cursor-pointer {cursor: pointer}

.EmailAddress a, .LinkedInTitle a {
  border-color: #1A3F45;
  text-decoration: none;
  color: #2C2C2C;
  border-width: 0px 0px 1px;
  border-style: solid;
  padding-bottom: 1px;
  transition: background 150ms ease-in-out 0s;
}

/******* t-b-r-l *******/
.top-0 {top: 0}
.top-60px {top: 60px}
.top-90px {top: 90px}
.top-200px {top: 200px}
.top-250px {top: 250px}
.top-87p {top: 87%}
.top-4p5vw {top: 4.5vw}
.top-7vw {top: 7vw}
.top-12vw {top: 12vw}
.top-16vw {top: 16vw}
.top-32vw {top: 32vw}
.top-minus-30p5vw {top: -30.5vw}

.right-0 {right: 0}
.right-60px {right: 60px}
.right-90px {right: 90px}
.right-130px {right: 130px}
.right-12vw {right: 12vw}
.right-32vw {right: 32vw}

.left-0 {left: 0}
.left-60px {left: 60px}
.left-90px {left: 90px}
.left-130px {left: 130px}
.left-4p5vw {left: 4.5vw}
.left-7vw {left: 7vw}
.left-12vw {left: 12vw}
.left-16vw {left: 16vw}
.left-32vw {left: 32vw}

.bottom-0 {bottom: 0}
.bottom-60px {bottom: 60px}
.bottom-90px {bottom: 90px}
.bottom-20p {bottom: 20%}
.bottom-12vw {bottom: 12vw}
.bottom-32vw {bottom: 32vw}

/******* z-index *******/
.z-100 {z-index: 100}
.z-99 {z-index: 99}
.z-98 {z-index: 98}
.z-20 {z-index: 20}
.z-14 {z-index: 14}
.z-8 {z-index: 8}
.z-1 {z-index: 1}

/******* margin *******/
.mar-auto {margin: auto}

.mar-l-auto {margin-left: auto}
.mar-l-minus-70px {margin-left: -70px}
.mar-l-minus-10vw {margin-left: -10vw}
.mar-l-minus-5vw {margin-left: -5vw}
.mar-l-10px {margin-left: 10px}
.mar-l-20px {margin-left: 20px}
.mar-l-50px {margin-left: 50px}
.mar-l-8p {margin-left: 8%}

.mar-r-auto {margin-right: auto}
.mar-r-20px {margin-right: 20px}
.mar-r-40px {margin-right: 40px}
.mar-r-8p {margin-right: 8%}

.mar-t-10px {margin-top: 10px}
.mar-t-5px {margin-top: 5px}
.mar-t-6p {margin-top: 6%}
.mar-t-2p {margin-top: 2%}

/******* padding *******/
.pad-0-notImp {padding: 0 !important}

.pad-40px {padding: 40px}
.pad-30px {padding: 30px}
.pad-20px {padding: 20px}
.pad-10px {padding: 10px}
.pad-5px {padding: 5px}
.pad-6p {padding: 6%}
.pad-4p {padding: 4%}
.pad-3p {padding: 3%}
.pad-2p {padding: 2%}
.pad-1p5rem {padding: 1.5rem}
.pad-3vw {padding: 3vw}

.pad-tb-3p {padding-top: 3%; padding-bottom: 3%}
.pad-tb-30px {padding-top: 30px; padding-bottom: 30px}

.pad-t-70px {padding-top: 70px}
.pad-t-60px {padding-top: 60px}
.pad-t-49px {padding-top: 49px}
.pad-t-40px {padding-top: 40px}
.pad-t-30px {padding-top: 30px}
.pad-t-20px {padding-top: 20px}
.pad-t-15px {padding-top: 15px}
.pad-t-10px {padding-top: 10px}
.pad-t-8p {padding-top: 8%}
.pad-t-6p {padding-top: 6%}
.pad-t-5p {padding-top: 5%}
.pad-t-4p {padding-top: 4%}
.pad-t-3p {padding-top: 3%}
.pad-t-2p {padding-top: 2%}
.pad-t-1p {padding-top: 1%}

.pad-b-40px {padding-bottom: 40px}
.pad-b-20px {padding-bottom: 20px}
.pad-b-10px {padding-bottom: 10px}
.pad-b-15p {padding-bottom: 15%}
.pad-b-8p {padding-bottom: 8%}
.pad-b-6p {padding-bottom: 6%}
.pad-b-5p {padding-bottom: 5%}
.pad-b-4p {padding-bottom: 4%}
.pad-b-3p {padding-bottom: 3%}
.pad-b-2p {padding-bottom: 2%}
.pad-b-1p {padding-bottom: 1%}
.pad-b-2rem {padding-bottom: 2rem}

.pad-l-80px {padding-left: 80px}
.pad-l-50px {padding-left: 50px}
.pad-l-40px {padding-left: 40px}
.pad-l-20px {padding-left: 20px}
.pad-l-10px {padding-left: 10px}
.pad-l-5px {padding-left: 5px}
.pad-l-16p {padding-left: 16%}
.pad-l-10p {padding-left: 10%}
.pad-l-8p {padding-left: 8%}
.pad-l-6p {padding-left: 6%}
.pad-l-4p {padding-left: 4%}
.pad-l-3p {padding-left: 3%}
.pad-l-2p {padding-left: 2%}

.pad-r-80px {padding-right: 80px}
.pad-r-40px {padding-right: 40px}
.pad-r-20px {padding-right: 20px}
.pad-r-10px {padding-right: 10px}
.pad-r-5px {padding-right: 5px}
.pad-r-16p {padding-right: 16%}
.pad-r-12p {padding-right: 12%}
.pad-r-10p {padding-right: 10%}
.pad-r-8p {padding-right: 8%}
.pad-r-6p {padding-right: 6%}
.pad-r-4p {padding-right: 4%}
.pad-r-3p {padding-right: 3%}
.pad-r-2p {padding-right: 2%}

/******* width *******/
.width-fit {width: fit-content}
.width-630px {width: 630px}
.width-600px {width: 600px}
.width-450px {width: 450px}
.width-400px {width: 400px}
.width-330px {width: 330px}
.width-200px {width: 200px}
.width-100px {width: 100px}
.width-30px {width: 30px}
.width-18vw {width: 18vw}
.width-42vw {width: 42vw}
.width-58vw {width: 58vw}
.width-82vw {width: 82vw}
.width-100vw {width: 100vw}
.width-110vw {width: 110vw}
.width-100p {width: 100%}
.width-90p {width: 90%}
.width-64p {width: 64%}
.width-60p {width: 60%}
.width-50p {width: 50%}
.width-40p {width: 40%}
.width-30p {width: 30%}
.width-12p5p {width: 12.5%}
.width-6p25p {width: 6.25%}
.width-3p {width: 3%}
.width-3rem {width: 3rem}
.width-1p5rem {width: 1.5rem}

/******* height *******/
.height-auto {height: auto}
.height-3p {height: 3%}
.height-5p {height: 5%}
.height-40p {height: 40%}
.height-100p {height: 100%}
.height-1px {height: 1px}
.height-15px {height: 15px}
.height-60px {height: 60px}
.height-330px {height: 330px}
.height-450px {height: 450px}
.height-630px {height: 630px}
.height-18vw {height: 18vw}
.height-42vw {height: 42vw}
.height-58vw {height: 58vw}
.height-82vw {height: 82vw}
.height-110vw {height: 110vw}
.height-3rem {height: 3rem}
.height-1p5rem {height: 1.5rem}

/******* min-width *******/
.min-width-40px {min-width: 40px}
.min-width-200px {min-width: 200px}
.min-width-230px {min-width: 230px}
.min-width-240px {min-width: 240px}
.min-width-280px {min-width: 280px}
.min-width-320px {min-width: 320px}
.min-width-345px {min-width: 345px}
.min-width-350px {min-width: 350px}
.min-width-430px {min-width: 430px}

/******* min-height *******/
.min-height-100vh {min-height: 100vh}
.min-height-5p {min-height: 5%}
.min-height-200px {min-height: 200px}
.min-height-230px {min-height: 230px}
.min-height-240px {min-height: 240px}
.min-height-280px {min-height: 280px}
.min-height-320px {min-height: 320px}
.min-height-345px {min-height: 345px}
.min-height-350px {min-height: 350px}
.min-height-430px {min-height: 430px}

/******* max-width *******/
.max-width-70p {max-width: 70%}
.max-width-100p {max-width: 100%}
.max-width-30px {max-width: 30px}
.max-width-40px {max-width: 40px}
.max-width-230px {max-width: 230px}
.max-width-330px {max-width: 330px}
.max-width-450px {max-width: 450px}
.max-width-600px {max-width: 600px}
.max-width-630px {max-width: 630px}

/******* max-height *******/
.max-height-30px {max-height: 30px}
.max-height-230px {max-height: 230px}
.max-height-330px {max-height: 330px}
.max-height-365px {max-height: 365px}
.max-height-400px {max-height: 400px}
.max-height-450px {max-height: 450px}
.max-height-630px {max-height: 630px}

/******* align *******/
.ver-align-mid {vertical-align: middle}

/******* display *******/
.none {display: none}
.block {display: block}
.flex {display: flex}
.flex-dir-col {flex-direction: column}
.flex-wrap {flex-wrap: wrap}
.flex-1 {flex: 1}
.flex-shrink0 {flex-shrink: 0}
.align-i-center {align-items: center}
.justify-i-left {justify-items: left}
.justify-i-center {justify-items: center}
.justify-center {justify-content: center}
.justify-between {justify-content: space-between}
.flex-col{flex-direction:column}
.inline-flex {display: inline-flex}
.flex-basis-88 {flex-basis: 88%}
.flex-basis-75 {flex-basis: 75%}
.flex-basis-70 {flex-basis: 70%}
.flex-basis-60 {flex-basis: 60%}
.flex-basis-55 {flex-basis: 55%}
.flex-basis-54 {flex-basis: 54%}
.flex-basis-50 {flex-basis: 50%}
.flex-basis-46 {flex-basis: 46%}
.flex-basis-40 {flex-basis: 40%}
.flex-basis-30 {flex-basis: 30%}
.flex-basis-25 {flex-basis: 25%}
.flex-basis-20 {flex-basis: 20%}
.flex-basis-12 {flex-basis: 12%}

/******* background *******/
.bac-rgba-115-113-132-0p2 {background: rgba(115, 113, 132, 0.2)}

/******* text *******/
.color-white {color: white}
.color-red {color: red}
.color-blue-link {color: #035871}
.color-1A3F45 {color: #1A3F45}
.color-2C {color: #2C2C2C}

.text-underline {text-decoration: underline}

.text-al-center {text-align: center}
.text-al-left {text-align: left}
.text-ver-align-mid {vertical-align: middle}
.text-wrap {text-wrap: wrap}

.line-height-3Rem {line-height: 3rem}

.font-size-larger {font-size: larger}
.font-size-6em {font-size: 6em}
.font-size-4em {font-size: 4em}
.font-size-3em {font-size: 3em}
.font-size-2p5em {font-size: 2.5em}
.font-size-2em {font-size: 2em}
.font-size-1p5em {font-size: 1.5em}
.font-size-1p3em {font-size: 1.3em}
.font-size-1p2em {font-size: 1.2em}
.font-size-1em {font-size: 1em}
.font-size-max-7em {font-size: max(7em,2em)}

.font-weight-400 {font-weight: 400} /* Regular */
.font-weight-500 {font-weight: 500} /* Medium */
.font-weight-700 {font-weight: 700} /* Bold */

.font-fam-con-light {font-family: congenial-light,sans-serif}
.font-fam-con {font-family: congenial,sans-serif}

/******* background *******/
.bac-col-white {background-color: white}
.bac-col-f6 {background-color: #f6f6f6 !important}
.bac-col-2C {background-color: #2C2C2C}
.bac-col-DAE7E6 {background-color: #DAE7E6}
.bac-col-D3EBEE {background-color: #D3EBEE}
.bac-col-FAFAF2 {background-color: #FAFAF2}
.bac-col-B5DEE3 {background-color: #B5DEE3}
.bac-col-8ECCD4 {background-color: #8ECCD4}
.bac-col-ECEFF1 {background-color: #ECEFF1}
.bac-col-1A3F45 {background-color: #1A3F45}
.bac-col-rgba293033 {background-color: rgba(29,30,33,var(--bg-opacity))}
.bac-col-E9ECEA {background-color: #E9ECEA}
.bac-col-EDE8EA {background-color: #EDE8EA}
.bac-col-E6EFEE {background-color: #E6EFEE}
.bac-col-F1F9F9 {background-color: #F1F9F9}
/* circle colours */
.bac-col-CDDFDD {background-color: #CDDFDD}
.bac-col-D2C6CB {background-color: #D2C6CB}
.bac-col-A8B3AB {background-color: #A8B3AB}

.bac-rep-no {background-repeat: no-repeat}

.bac-transparent {background: transparent}

/******* opacity *******/
.opacity-0 {opacity: 0}

.bg-opacity-25 { --bg-opacity: 0.25}

/******* border *******/
.border-none {border: none}
.border-underline-transparent {border-bottom: 2px solid transparent}
.border-1px-solid-ccc {border: 1px solid #ccc}
.border-1px-solid-D3EBEE {border: 1px solid #D3EBEE}
.border-1px-solid-FAFAF2 {border: 1px solid #FAFAF2}
.border-1px-solid-EDE8EA {border: 1px solid #EDE8EA}
.border-1px-solid-E6EFEE {border: 1px solid #E6EFEE}
.border-2px-solid-F1F9F9 {border: 2px solid #F1F9F9}
.border-5px-solid-E9ECEA {border: 5px solid #E9ECEA}
.border-5px-solid-DAE7E6 {border: 5px solid #DAE7E6}
.border-rad-100p {border-radius: 100%}
.border-rad-50p {border-radius: 50%}
.border-rad-50px {border-radius: 50px}
.border-rad-8px {border-radius: 8px}
.border-rad-5px {border-radius: 5px}
.border-rad-4px {border-radius: 4px}
.border-sol-4px-276068 {border: solid 4px #276068}
.border-sol-4px-FAFA8B {border: solid 4px #FAFA8B}
.border-l-sol-5px-A8B3AB {border-left: solid 5px #A8B3AB}

.AboutImage img {border-radius: 50%; border: 6px solid #D2C6CB}
.AboutImage div {border-radius: 50%}

/******* tailwind *******/
.bac-col-f1f9f9-t6 {
  background-color: rgba(241,249,249,.6);
}
.bac-col-CDDFDD-t6 {
  background-color: rgba(205,223,221,.6);
}


/******* appearance *******/
.appear-none {appearance: none}

/******* list *******/
.list-s-t-circle {list-style-type: circle}
.list-s-p-inside {list-style-position: inside}

/******* box-shadow *******/
.box-shadow-card {box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2)}

/******* hover *******/
.a-hover-color-white a:hover {color: white !important}
.hover-bac-col-2C:hover {background-color: #2C2C2C}
.hover-opacity-0p6-notImp:hover .pro-img {opacity: 0.6 !important; transition: none}
.hover-bac-col-trans:hover .pro-img-con {background-color: transparent !important; transition: none}
.hover-scale-100:hover, .scale-100 { --transform-scale-x: 1; --transform-scale-y: 1}
.hover-bg-opacity-100:hover {--bg-opacity: 1}
.hover-box-sha-08160:hover {box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2)}
.hover-underline-blue:hover {border-bottom: 2px solid #035871}
.hover-underline-pink:hover {border-bottom: 4px solid #D2C6CB}

/******* focus *******/
.focus-outline-none:focus {outline: 2px solid transparent; outline-offset: 2px}

/******* filter *******/
.filter-blur-50px {filter: blur(50px)}

/******* scale *******/
.scale-90 { --transform-scale-x: .9; --transform-scale-y: .9}

/******* transition *******/
.trans-bac-1s-lin {transition: background 1s linear}

.trans-opac-1s-lin {transition: opacity 1s linear}

.trans-dis-1s-lin {transition: display 1s linear}

.tran-dur-p1s {transition-duration: .1s}

.tran-prop-B2T {
  transition-property: background-color,border-color,color,fill,stroke,opacity,box-shadow,transform;
}

.tran-t-func-B2T {
  transition-timing-function: cubic-bezier(.4,0,1,1);
}

/******* transform *******/
.transform-scale-1 {transform: scale(1)}

.transform-B2T {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

/******* Animation *******/
.animation-name-rotate {animation-name: rotate;}
.animation-name-growcircle {animation-name: growcircle}
.animation-name-flash {animation-name: flash}

.animation-delay-p5s {animation-delay: 0.5s}

.animation-dur-2s {animation-duration: 2s}
.animation-dur-1s {animation-duration: 1s}


@keyframes rotate {
  0%   {transform: rotate(0deg)}
  50%  {transform: rotate(80deg)}
  100%  {transform: rotate(0deg)}
}

@keyframes growcircle {
  0%   {transform: scale(1)}
  50%  {transform: scale(0.4)}
  100% {transform: scale(1)}
}

@keyframes flash {
  0%   {transform: scale3d(1,1,1)}
  50%  {transform: scale3d(1.2,1.2,1.2)}
  100% {transform: scale3d(1,1,1)}
}


/******* @media *******/
/******* min-width *******/
@media screen and (min-width: 640px) {
  minw321\:font-size-1p2em {font-size: 1.2em}
}

@media screen and (min-width: 640px) {
  .minw640\:p-y-1 {padding-top: 1rem; padding-bottom: 1rem}
}

@media screen and (min-width: 768px) {
  .minw768\:p-x-4 {padding-left: 4rem; padding-right: 4rem}
}

@media screen and (min-width: 769px) {
  .minw769\:bor-rad-8px {border-radius: 0px 8px 8px 0px}
  .minw769\:mar-auto-8p {margin: auto 8%}
  .minw769\:mar-l-m33p5vw {margin-left: -33.5vw}
  .minw769\:mar-l-m35p5vw {margin-left: -35.5vw}
  .minw769\:top-33vw {top: 33vw}
  .minw769\:top-65vw {top: 65vw}
  .minw769\:top-31p25vw {top: 31.25vw}
  .minw769\:top-m15vw {top: -15vw}
  .minw769\:top-m30p5vw {top: -30.5vw}
  .minw769\:top-m40vw {top: -40vw}
  .minw769\:pad-lr-6p7p {padding-left: 6%; padding-right: 7%}
  .minw769\:align-i-center {align-items: center}
  .minw769\:left-30vw {left: 30vw}
}

@media screen and (min-width: 820px) {
  .minw820\:left-30vw {left: 30vw}
}

@media screen and (min-width: 896px) {
  .minw820\:left-29p5vw {left: 29.5vw}
}

@media screen and (min-width: 912px) {
  .minw912\:top-m16vw {top: -16vw}
}

@media screen and (min-width: 962px) {
  .minw962\:top-m18p7vw {top: -18.7vw}
  .minw962\:mar-l-m32vw {margin-left: -32vw}
  .minw962\:top-31vw {top: 31vw}
}

@media screen and (min-width: 962px) {
  .minw972\:font-size-1p3em {font-size: 1.3em}
  .minw972\:font-size-2em {font-size: 2em}
}

@media screen and (min-width:1024px) {
  .minw1024\:p-x-6 {padding-left:6rem; padding-right:6rem}
  .minw1024\:top-198px {top: 198px}
  .minw1024\:top-24p5vw {top: 24.5vw}
  .minw1024\:top-50vw {top: 50vw}
  .minw1024\:top-30vw {top: 30vw}
  .minw1024\:top-m25vw {top: -25vw}
  .minw1024\:top-m50vw {top: -50vw}
  .minw1024\:top-m60vw {top: -60vw}
  .minw1024\:left-275px {left: 275px}
  .minw1024\:left-28vw {left: 28vw}
  .minw1024\:left-30vw {left: 30vw}
}

@media screen and (min-width:1080px) {
  .minw1080\:top-24vw {top: 24vw}
  .minw1080\:left-28vw {left: 28vw}
}

@media screen and (min-width:1120px) {
  .minw1120\:font-size-1p5em {font-size: 1.5em}
  .minw1120\:font-size-2p5em {font-size: 2.5em}
}

@media screen and (min-width:1180px) {
  .minw1180\:top-m55vw {top: -55vw}
  .minw1180\:top-24p5vw {top: 24.5vw}
  .minw1180\:top-48vw {top: 48vw}
  .minw1180\:left-29vw {left: 29vw}
}

@media screen and (min-width:1280px) {
  .minw1280\:top-246px {top: 246px}
  .minw1280\:top-40vw {top: 40vw}
  .minw1280\:top-m30p5vw {top: -30.5vw}
  .minw1280\:top-m62vw {top: -62vw}
  .minw1280\:top-m67vw {top: -67vw}
  .minw1280\:top-m69vw {top: -69vw}
  .minw1280\:left-325px {left: 325px}
  .minw1280\:margin-left-m35p5vw {margin-left: -35.5vw}
}

@media screen and (min-width:1366px) {
  .minw1366\:top-268px {top: 268px}
  .minw1366\:left-345px {left: 345px}
}

@media screen and (min-width:1400px) {
  .minw1400\:top-m31p75vw {top: -31.75vw}
  .minw1400\:mar-l-m36vw {margin-left: -36vw}
}

@media screen and (min-width:1600px) {
  .minw1600\:mar-l-m38p5vw {margin-left: -38.5vw}
  .minw1600\:top-m34vw {top: -34vw}
}

@media screen and (min-width:1920px) {
  .minw1920\:mar-l-m41vw {margin-left: -41vw}
  .minw1920\:top-35vw {top: 35vw}
  .minw1920\:top-m37p5vw {top: -37.5vw}
  .minw1920\:top-m70vw {top: -70vw}
  .minw1920\:top-m82vw {top: -82vw}
  .minw1920\:mar-t-m27vw {margin-top: -27vw}
}

@media screen and (min-width:2000px) {
  .minw2000\:top-14vw {top: 14vw}
}

@media screen and (min-width:2256px) {
  .minw2256\:mar-l-m43vw {margin-left: -43vw}
  .minw2256\:top-m40p25vw {top: -40.25vw}
  .minw2256\:top-12vw {top: 12vw}
}

@media screen and (min-width:2560px) {
  .minw2560\:mar-l-m45vw {margin-left: -45vw}
  .minw2560\:top-m42vw {top: -42vw}
  .minw2560\:top-10p5vw {top: 10.5vw}
}


/******* max-width *******/
@media screen and (max-width: 2560px) {
  .maxw2560\:font-size-7em {font-size: 7em}
  .maxw2560\:font-size-2em {font-size: 2em}
  .maxw2560\:pad-30px {padding: 30px}
  .maxw2560\:width-64p {width: 64%}
}

@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1024px) {
  .maxw1024\:font-size-1p5em {font-size: 1.5em}
  .maxw1024\:pad-20px {padding: 20px}
  .maxw1024\:pad-5px {padding: 5px}
}

@media screen and (max-width: 860px) {
  .maxw860\:font-size-1p4em {font-size: 1.4em}
  .maxw860\:width-80p {width: 80%}
}

@media screen and (max-width: 800px) {
  .maxw800\:pad-lr-30px {padding-left: 30px; padding-right: 30px}
}

@media screen and (max-width: 768px) {
  .maxw768\:mar-auto {margin-left: auto; margin-right: auto}
  .maxw768\:mar-auto-20px {margin: auto 20px}
  .maxw768\:center-circles {left: 50%; margin-left: -55vw}
  .maxw768\:top-225px {top: 225px}
  .maxw768\:top-315px {top: 315px}
  .maxw768\:top-m300px {top: -300px}
  .maxw768\:top-54vw {top: 54vw}
  .maxw768\:top-88vw {top: 88vw}
  .maxw768\:top-m10vw {top: -10vw}
  .maxw768\:top-m26vw {top: -26vw}
  .maxw768\:font-size-5em {font-size: 5em}
  .maxw768\:font-size-4em {font-size: 4em}
  .maxw768\:font-size-2em {font-size: 2em}
  .maxw768\:font-size-1p5em {font-size: 1.5em}
  .maxm768\:text-cen {text-align: center}
  .maxw768\:max-width-100p {max-width: 100%}
  .maxw768\:pad-t-12p {padding-top: 12%}
  .maxw768\:pad-t-10px {padding-top: 10px}
  .maxw768\:pad-b-20px {padding-bottom: 20px}
  .maxw768\:pad-tb-5px {padding-top: 5px; padding-bottom: 5px}
  .maxw768\:pad-tb-10px {padding-top: 10px; padding-bottom: 10px}
  .maxw768\:pad-lr-5px {padding-left: 5px; padding-right: 5px}
  .maxw768\:pad-lr-10px {padding-left: 10px; padding-right: 10px}
  .maxw768\:pad-lr-0px {padding-left: 0px; padding-right: 0px}
  .maxw768\:mar-lr-10px {margin-left: 10px; margin-right: 10px}
  .maxw768\:mar-lr-20px {margin-left: 20px; margin-right: 20px}
  .maxw768\:pad-r-0p {padding-right: 0%}
  .maxw768\:pad-r-5px {padding-right: 5px}
  .maxw768\:pad-l-0p {padding-left: 0%}
  .maxw768\:pad-l-5px {padding-left: 5px}
  .maxw768\:width-100p {width: 100%}
  .maxw768\:flex-wrap {flex-wrap: wrap}
  .maxw768\:flex-100p {flex-basis: 100%}
  .maxw768\:bac-col-2C {background-color: #2C2C2C}
  .maxw768\:col-white {color: white}
}

@media screen and (max-width: 660px) {
  .maxw660\:flex-100p {flex-basis: 100%}
}

@media screen and (max-width: 640px) {
  .maxw640\:top-190px {top: 190px}
  .maxw640\:top-260px {top: 260px}
  .maxw640\:top-54vw {top: 54vw}
  .maxw640\:top-m12vw {top: -12vw}
  .maxw640\:pad-4p {padding: 4%}
  .maxw640\:pad-tb-10px {padding-top: 10px; padding-bottom: 10px}
  .maxw640\:pad-tb-6p {padding-top: 6%; padding-bottom: 6%}
  .maxw640\:pad-tb-10p {padding-top: 10%; padding-bottom: 10%}
  .maxw640\:pad-tb-8p-4p {padding-top: 8%; padding-bottom: 4%}
  .maxw640\:pad-tb-8p-10p {padding-top: 8%; padding-bottom: 10%}
  .maxw640\:pad-tb-0p5rem {padding-top: 0.5rem; padding-bottom: 0.5rem}
  .maxw640\:pad-t-60px {padding-top: 60px}
  .maxw640\:pad-t-20px {padding-top: 20px}
  .maxw640\:pad-t-4p {padding-top: 4%}
  .maxw640\:pad-b-4p {padding-bottom: 4%}
  .maxw640\:pad-l-0px {padding-left: 0px}
  .maxw640\:pad-l-8p {padding-left: 8%}
  .maxw640\:pad-r-8p {padding-right: 8%}

  .maxw640\:flex-wrap {flex-wrap: wrap}
  .maxw640\:flex-100p {flex-basis: 100%}
  .maxw640\:flex-50p {flex-basis: 50%}

  .maxw640\:text-a-l {text-align: left}

  .maxw640\:width-100p {width: 100%}
}

@media screen and (max-width: 580px) {
  .maxw580\:display-none {display: none}
}

@media screen and (max-width: 565px) {
  .maxw565\:top-170px {top: 170px}
  .maxw565\:top-115vw {top: 115vw}
  .maxw565\:top-60vw {top: 60vw}
  .maxw565\:top-m8vw {top: -8vw}
  .maxw565\:display-none {display: none}
  .maxw565\:pad-rl-30px {padding-right: 30px; padding-left: 30px}
}

@media screen and (max-width: 480px) {
  .maxw480\:top-5vw {top: 5vw}
  .maxw480\:top-74vw {top: 74vw}
  .maxw480\:top-m20vw {top: -20vw}
}

@media screen and (max-width: 440px) {
  .maxw440\:top-160vw {top: 160vw}
  .maxw440\:top-135vw {top: 135vw}
  .maxw440\:top-88vw {top: 88vw}
  .maxw440\:top-30vw {top: 30vw}
  .maxw440\:top-18vw {top: 18vw}
  .maxw440\:top-m12vw {top: -12vw}
  .maxw440\:top-175px {top: 175px}
  .maxw440\:top-190px {top: 190px}
  .maxw440\:top-m200px {top: -200px}
  .maxw440\:top-210px {top: 210px}
  .maxw440\:top-0px {top: 0px}
  .maxw440\:pad-0px {padding: 0px}
  .maxw440\:pad-40px {padding: 40px}
  .maxw440\:pad-t-80px {padding-top: 80px}
  .maxw440\:pad-t-20px {padding-top: 20px}
  .maxw440\:pad-tb-20px {padding-top: 20px; padding-bottom: 20px}
  .maxw440\:pad-lr-5px {padding-left: 5px; padding-right: 5px}
  .maxw440\:pad-l-4p {padding-left: 4%}
  .maxw440\:pad-l-0px {padding-left: 0px}
  .maxw440\:pad-r-4p {padding-right: 4%}
  .maxw440\:pad-r-0px {padding-right: 0px}
  .maxw440\:mar-t-10px {margin-top: 10px}
  .maxw440\:mar-b-15p {margin-bottom: 15%}
  .maxw440\:mar-l-0px {margin-left: 0px}
  .maxw440\:mar-lr-5px {margin-left: 5px; margin-right: 5px}
  .maxw440\:flex-wrap {flex-wrap: wrap}
  .maxw440\:flex-100p {flex-basis: 100%}
  .maxw440\:font-size-1p6em {font-size: 1.6em}
}

@media screen and (max-width: 375px) {
  .maxw375\:top-165px {top: 165px}
  .maxw375\:top-88vw {top: 88vw}
  .maxw375\:pad-l-20px {padding-left: 20px}
  .maxw375\:mar-l-20px {margin-left: 20px}
  .maxw375\:font-size-7em {font-size: 7em}
}

@media screen and (max-width: 320px) {
  .maxw320\:font-size-1em {font-size: 1em}
}

@media screen and (max-width: 280px) {
  .maxw280\:top-12vw {top: 12vw}
  .maxw280\:top-180vw {top: 180vw}
}
